// 财务管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/finance",
        name: "finance",
        component: () => import("@/views/Home.vue"),
        meta: { title: "财务管理" },
        children: [
            {
                path: "/finance/reconciliation",
                name: "reconciliation",
                meta: { title: "交易流水", isShow: true },
                component: () => import("@/views/finance/reconciliation/Reconciliation.vue")
            },
            {
                path: "/finance/bankCard",
                name: "bankCard",
                meta: { title: "银行卡", isShow: true },
                component: () => import("@/views/finance/bankCard/BankCard.vue")
            },
            {
                path: "/finance/cashRecord",
                name: "cashRecord",
                meta: { title: "提现记录", isShow: true },
                component: () => import("@/views/finance/cashRecord/CashRecord.vue")
            },
        ]
    }
]