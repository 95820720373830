// 订单管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/order",
        name: "order",
        component: () => import("@/views/Home.vue"),
        meta: { title: "订单管理" },
        children: [
            {
                path: "/order/centerList",
                name: "orderCenter",
                meta: { title: "订单中心", isShow: true },
                component: () => import("@/views/order/orderCenter/List.vue"),
                children: [
                    {
                        path: "/order/centerDetail/:id",
                        name: "orderDetail",
                        meta: { title: "订单详情", },
                        component: () => import("@/views/order/orderCenter/Detail.vue"),
                    },
                ]
            },
            {
                path: "/order/saleList",
                name: "saleList",
                meta: { title: "售后工单", isShow: true },
                component: () => import("@/views/order/sale/SaleList.vue"),
                children: [
                    {
                        path: "/order/saleDetail/:id",
                        name: "saleDetail",
                        meta: { title: "售后详情", },
                        component: () => import("@/views/order/sale/SaleDetail.vue"),
                    },
                ]
            },
        ]
    }
]