// 商品管理路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/goods",
        name: "goods",
        component: () => import("@/views/Home.vue"),
        meta: { title: "商品管理" },
        children: [
            {
                path: "/goods/goodsList",
                name: "goodsList",
                meta: { title: "商品列表", isShow: true },
                component: () => import("@/views/goods/goodsList/GoodsList.vue"),
                children: [
                    {
                        path: "/goods/goodDetail/:id",
                        name: "goodsList",
                        meta: { title: "商品详情" },
                        component: () => import("@/views/goods/goodsList/GoodDetail.vue"),
                    },
                ]
            },
            {
                path: "/goods/evaluate",
                name: "evaluate",
                meta: { title: "评价管理", isShow: true },
                component: () => import("@/views/goods/evaluate/Evaluate.vue"),
            }
        ]
    }
]