// 招标路由
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default [
    {
        path: "/invite",
        name: "invite",
        component: () => import("@/views/Home.vue"),
        meta: { title: "招标管理" },
        children: [
            {
                path: "/invite/inviteList",
                name: "inviteList",
                meta: { title: "我的询价", isShow: true },
                component: () => import("@/views/invite/inviteList/InviteList.vue"),
                children: [
                    {
                        path: "/invite/inviteDetail/:id",
                        name: "inviteDetail",
                        meta: { title: "招采详情" },
                        component: () => import("@/views/invite/inviteList/InviteDetail.vue"),
                    },
                    {
                        path: "/invite/releaseInvite",
                        name: "releaseInvite",
                        meta: { title: "发布询价" },
                        component: () => import("@/views/invite/inviteList/ReleaseInvite.vue"),
                    },
                    {
                        path: "/invite/quotationList/:cgId",
                        name: "quotationList",
                        meta: { title: "报价列表" },
                        component: () => import("@/views/invite/inviteList/QuotationList.vue"),
                    },
                ]
            },
        ]
    }
]