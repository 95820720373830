import Vue from 'vue'
import VueRouter from 'vue-router'
import order from "@/router/order/router";
import goods from "@/router/goods/router";
import inventory from "@/router/inventory/router";
import finance from "@/router/finance/router";
import invite from "@/router/invite/router";
import system from "@/router/system/router";


// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch((err) => { });
};
Vue.use(VueRouter);
// 所有路由集合
let allRouterArr = [
    ...order,
    ...goods,
    ...inventory,
    ...finance,
    ...invite,
    ...system,
];
// //  动态路由
// let powerListArr = JSON.parse(localStorage.getItem("powerListArr"));
// if (powerListArr) {
//     let powerListObj = powerListArr.reduce((pre, current) => {
//         pre[current.modular] = "xxx";
//         return pre;
//     }, {});
//     let childPowerListArr = [];
//     powerListArr.map((item) => {
//         if (item.children.length > 0) {
//             item.children.map((subitem) => {
//                 childPowerListArr.push(subitem);
//             });
//         }
//     });
//     let childPowerListObj = childPowerListArr.reduce((pre, current) => {
//         pre[current.modular] = "xxx";
//         return pre;
//     }, {});

//     let newAllRouterArr = []; // 过滤后的路由数组
//     allRouterArr.map((item) => {
//         let subNewAllRouterArr = [];
//         if (powerListObj[item.name]) {
//             newAllRouterArr.push(item);
//             item.children.forEach((subItem) => {
//                 if (childPowerListObj[subItem.name]) {
//                     subNewAllRouterArr.push(subItem);
//                 }
//             });
//             if (item.children.length > 0) {
//                 item.children = subNewAllRouterArr;
//             }
//         }
//     });
//     allRouterArr = newAllRouterArr;
// }

const routes = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: '/login',  // 登录页面
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
    },
    {
        path: '/home',  // 首页
        name: 'home',
        redirect: "/home/console",
        meta: { title: "采购商" },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        children: [
            {
                path: "/home/console", // 控制台
                name: "console",
                meta: { title: "首页" },
                component: () => import(/* webpackChunkName: "console" */ '@/views/public/Console.vue')
            }
        ]
    },
    {
        path: '/home',  // 首页
        name: 'home',
        redirect: "/home/dataCenter",
        meta: { title: "采购商" },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        children: [
            {
                path: "/home/dataCenter", // 控制台
                name: "dataCenter",
                meta: { title: "采购中心" },
                component: () => import(/* webpackChunkName: "dataCenter" */ '@/views/public/DataCenter.vue')
            }
        ]
    },
    ...allRouterArr
]

const router = new VueRouter({
    routes
})

export default router
